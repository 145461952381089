$color-cream: #fffdf5;
$color-dark-cream: #f6f3eb;
$color-white: #f5f5f7;
$color-pampas: #f2f0e9;
$color-dodger-blue: #4770fd;
$color-orange: #f04e23;
$color-green-yellow: greenyellow;
$color-yellow: #ffea2f;
$color-grey: grey;
$color-grey-25: #80808025;
$color-grey-50: #80808050;
$color-grey-75: #80808075;
$color-charcoal: #1c1c1c;
$color-black: #161617;
$font-stack-serif: "Inter", sans-serif;
$font-stack-sans: "Inter", sans-serif;
// $font-stack-mono:'Roboto Mono', monospace;

@font-face {
  font-family: "Inter";
  src: url("/assets/css/fonts/Inter-VariableFont_slnt,wght.ttf");
}

/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 900.0;
*
* available axes:

* 'wght' (range from 300.0 to 900.0)

*/

@font-face {
  font-family: "Satoshi-Variable";
  src: url("/assets/css/fonts/Satoshi-Variable.woff2") format("woff2"),
    url("/assets/css/fonts/Satoshi-Variable.woff") format("woff"),
    url("/assets/css/fonts/Satoshi-Variable.ttf") format("truetype");
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
  // padding: 16px;
  display: flex;
  // background: linear-gradient(1deg, #1e1e1e 0%, #4d4d4d 72.92%, #b9bdc6 100%);
  // background: linear-gradient(1deg, #333 0.01%, #f04e23 100%);
  background-color: $color-white;
  overflow-x: hidden;
}

::selection {
  background: $color-black;
  color: $color-cream;
  /* WebKit/Blink Browsers */
}

::-moz-selection {
  background: $color-black;
  color: $color-cream;
}

.current {
  display: inline-block;
  color: $color-black;
  // font-weight: bold;
}

body {
  font-family: $font-stack-sans;
  -webkit-font-smoothing: antialiased;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0px auto;
  letter-spacing: -.05em;

  width: 100%;

  color: $color-grey;

  overscroll-behavior-y: none;
}

.headline-icon {
  top: 9px;
  position: relative;
}

.transparent {
  opacity: 0.25;
}

#build-item[data-char] {
  color: transparent;
  position: relative;
  white-space: nowrap;
}

#build-item[data-char]::after {
  animation-duration: 0.2s;
  animation-delay: 0.5s;
  animation-timing-function: steps(1);
  animation-fill-mode: backwards;
  content: attr(data-char);
  color: black;
  // position: absolute;
  // left: 0;
  // top: 0;
}

@keyframes glitch-switch {
  0% {
    content: attr(data-char-0);
  }

  10% {
    content: attr(data-char-1);
  }

  20% {
    content: attr(data-char-2);
  }

  30% {
    content: attr(data-char-3);
  }

  40% {
    content: attr(data-char-4);
  }

  50% {
    content: attr(data-char-5);
  }

  60% {
    content: attr(data-char-6);
  }

  70% {
    content: attr(data-char-7);
  }

  80% {
    content: attr(data-char-8);
  }

  90% {
    content: attr(data-char-9);
  }

  100% {
    content: attr(data-char-0);
  }
}

.section-title-container {
  display: flex;
  flex-direction: row;
  // align-items: center;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 26px;
}

.small {
  font-size: 12px;
}

.section-number {
  color: $color-grey-75 !important;
}

.section-title {
  flex: 1;
  color: $color-black;
  // font-weight: 500;
}

article,
p {
  display: block;
}

h1 {
  color: $color-black;
  font-family: $font-stack-serif;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  line-height: 1.25;
  font-weight: 400;
  word-wrap: break-word;

  a {
    cursor: pointer;
  }
}

h2,
h3,
h4,
h5,
h6,
p {
  font-family: $font-stack-serif;
  margin-block-start: 0;
  color: $color-grey;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  line-height: 1.25;
  //    text-transform: uppercase;
  font-weight: 400;
  word-wrap: break-word;

  a {
    // font-family: $font-stack-serif;
    cursor: pointer;
    // transition: margin-left 250ms ease-out;
    // border-bottom: 2px solid black;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  // font-weight: 600;
  // font-variation-settings: "wght" 600;
  letter-spacing: -0.05em;
}

title,
h1 {
  // -webkit-text-stroke-color: $color-black;
  // -webkit-text-stroke-width: 0.5px;
  // color: transparent;
  font-size: 4.5em;
  letter-spacing: -.05em;

  @media (max-width: 920px) {
    font-size: 3.75em;
  }
}

h2 {
  font-size: 3.75em;
}

h3 {
  font-size: 3em;
  line-height: 1;
  font-weight: 500;
}

h4 {
  font-size: 2.25em;
  line-height: 1;
  font-weight: 500;
}

h5 {
  font-size: 1.5em;
  line-height: 1.2em;
  font-weight: 500;
}

h6 {
  font-size: 1.25em;
  margin: 0.75em 0;
}

.subtitle {
  font-size: 1.5em;
  margin: 0.75em 0;
  line-height: 1.2em;
}

p {
  font-family: $font-stack-sans;
  font-size: 1em;
  line-height: 1.7;
  margin: 0px;
  letter-spacing: -0.02em;
}

code {
  padding: 2 0;
  background: $color-dark-cream;
  padding: 0 5px;
  border-radius: 4px;
  color: $color-black;
}

.image-caption {
  color: $color-grey;
  font-family: $font-stack-sans;
  font-size: 12px;
  font-style: normal;
}

// MARKDOWN IMAGE CAPTIONS
img+em {
  color: $color-grey;
  font-family: $font-stack-sans;
  font-size: 0.75em;
  font-style: normal;
  line-height: normal;
}

.subheading {
  font-size: 0.75em;
}

p a {
  text-decoration: underline !important;
  text-decoration-color: $color-grey-50 !important;
}

.container {
  padding: 0 16px;
  max-width: 608px;
  width: calc(100% - 24px);
  //   min-height: 100vh;
  flex: 1;
  animation: 0.5s fadein;
  margin: auto;

  @media (min-width: 920px) {
    // padding: 0 64px;
  }
}

.container-s {
  padding: 0 16px;
  max-width: 420px;
  width: calc(100% - 24px);
  //   min-height: 100vh;
  flex: 1;
  animation: 0.5s fadein;
  margin: auto;

}

.main_nav {
  // position: sticky;
  top: 16px;
  z-index: 999;
  border-radius: 44px;
  margin: auto;
  width: 100%;
  /* Default width */
  left: 0%;
  /* Position at left */
  transition: all 0.5s ease;

  /* Transition effect */
  a {
    text-decoration: none;
    font-size: 12px;
  }

  hr {
    height: 1px;
    background-color: $color-black;
    border: none;
    margin: 16px;

    @media (min-width: 920px) {
      margin: 16px 64px;
    }
  }
}

.main_nav.scrolled {
  position: sticky;
  // top: 16px;
  width: 50%;
  /* Less than 100% to 'shrink' the bar */
  min-width: 360px;
  left: 25%;
  /* Adjust to keep the bar centered */
  background: rgba(83, 83, 83, 0.4);
  // min-width: 420px;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 2px 0px,
    rgba(0, 0, 0, 0.16) 0px 4px 16px 0px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  // a, p {
  // 	color: rgb(55, 55, 55) !important;
  // }
}

nav {
  padding: 12px 16px;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  margin: auto;
  align-items: center;
}

.nav__home {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  // flex: 1;

  img {
    width: 48px;
    height: auto;
    // margin-right: 16px;
    border-radius: 100%;
    // border: 1px solid $color-pampas;
  }

  #home-image {
    width: 15.75px;
    height: 15px;
    border-radius: 6px;

    // background-size: contain;
    // background-position: center;
    // background-image: url(/assets/images/colederochie-logo.png);
    &:hover {
      outline-offset: 4px;
      outline: 1px solid black;
      transition: all 0.1s ease;
    }

    &:active {
      transform: scale(0.9);
    }
  }
}

.logo {
  // background-image: url(/assets/images/colederochie-logo.png);
  width: 22px;
  margin-top: 24px;
  // height: 21px;
  // background-size: cover;
}

// #drag {
// 	cursor: move;
// }
#home-text {
  #name {
    line-height: 150%;
  }

  #handle {
    color: $color-grey;
    line-height: 150%;
  }
}

.nav__items {
  // margin: auto 16px;
  flex: 1;
  text-align: right;
  justify-content: center;
  // align-items: baseline;
  // margin-top: 4px;
  display: flex;

  a {
    margin-left: 24px;
    border: none;
    font-weight: 400;
    text-decoration: none;
    font-size: 12px;
  }
}

#theme-toggle {
  cursor: pointer;
}

a {
  color: $color-grey;
  font-family: $font-stack-sans;
  text-decoration: none;
}

a:hover {
  color: $color-black;

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $color-black;
  }
}

// a:active {
// 	transform: scale(0.95) !important;
// }

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

article ul {
  list-style-type: circle;
  padding-left: 24px;
}

.link_li {
  margin-bottom: 12px;

  &:hover {
    transform: scale(1.01);
    // .work-thumbnail {
    // 	box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 8px 0px,
    // 		rgba(0, 0, 0, 0.16) 0px 4px 16px 0px;
    // }
  }
}

.section-title,
.title {
  color: $color-black;
}

.home_link_li {
  a {
    display: flex;

    hr {
      margin: 16px;
      flex: 1;
    }
  }

  &:hover {
    a {
      hr {
        border-bottom: 0.1px solid $color-black;
      }
    }
  }
}

.blog_li {
  margin-bottom: 32px;
  padding-left: 0px;
  display: flex;
  flex-direction: row;
}

.destination-icon {
  visibility: hidden;
  display: flex;
  flex: 1;
  justify-content: end;
  font-weight: 500;
}

.project-item {
  width: 100%;
  --icon-dim: 48px;

  img {
    visibility: hidden;
  }

  &:hover {
    background-color: #00000025;
    border-radius: 32px;
    transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    color: $color-grey;

    .destination-icon {
      visibility: visible;
    }

    img {
      visibility: visible;
      transition: all 4s cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    .icon-container {
      background-image: none !important;
    }
  }
}

.icon-container {
  min-width: var(--icon-dim);
  min-height: var(--icon-dim);
  width: var(--icon-dim);
  height: var(--icon-dim);
}

.case-study_li {
  display: flex;
  flex-direction: column;
  // margin-bottom: 64px;
  padding-left: 0px;

  a {
    text-decoration: none;
  }
}

#work {
  ul {
    display: flex;
    justify-content: space-between;
    column-gap: 24px;

    @media (max-width: 500px) {
      flex-direction: column;
      row-gap: 16px;
    }
  }

  li {
    flex: 1;
  }

  h5 {
    margin: 8px 0 4px 0;
  }
}

.work-thumbnail {
  aspect-ratio: 16/9;
  // width: 100%;
  // height: 50px;
  border-radius: 8px;
}

.transition-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}

.newsletter-card {
  border-radius: 16px;
  background: var(--Button-Stroke-Dark,
      linear-gradient(180deg, #2e2d2e 0%, #030203 100%));
  display: flex;
  padding: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;

  .feyPpp {
    border-radius: 24px;
  }
}

.format-text {
  max-width: 800px;
  word-wrap: break-word;
  margin: auto;

  p {
    margin: 0.75em 0;
  }

  p:first-child {
    margin-top: 0px;
  }

  p:last-child {
    margin-bottom: 0px;
  }
}

.view-link {
  text-align: right;
}

.home-post-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 60%;
}

.home-post-date {
  white-space: nowrap;
}

footer {
  // max-width: 1380px;
  padding: 12px 16px;
  //   margin: auto !important;
  text-align: center;

  p {
    color: $color-grey-50;
  }

  @media (min-width: 920px) {
    // padding: 0 64px;
  }

  // a {
  // 	margin-right: 12px;
  // }
}

.footer-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.tag {
  border: 1px solid $color-grey-50;
  border-radius: 4px;
  font-size: 12px;
  margin: 10px 10px 0 0;
  padding: 5px 10px;
  display: inline-block;
  text-decoration: none;

  &:hover {
    border: 1px $color-grey solid;
  }
}

.post-meta__item {
  margin-top: 20px;
}

.post-meta__title {
  color: $color-grey;
}

.pagination__title {
  color: $color-grey;
  margin-bottom: 10px;
}

hr {
  border: 0px;
  border-bottom: 0.1px solid grey;
  margin: 20px auto;
}

.sticky {
  position: sticky;
  top: 50px;
}

.case-study_meta-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.case-study_meta {
  display: flex;
  justify-content: space-between;
}

.case-study_summary {
  flex-basis: 50%;

  p {
    margin-top: 0px;
  }

  a {
    float: right;
  }
}

.page-heading {
  margin: 60px 0px;
}

.margin-y {
  margin: 60px 0px;
}

.margin-y-s {
  margin: 24px auto;
}

.margin-y-s-top {
  margin-top: 24px;
}

.margin-y-s-bottom {
  margin-bottom: 24px;
}

.margin-y-m {
  margin: 48px auto;
}

.margin-y-l {
  margin: 120px auto;
}

.marginRight-16 {
  margin-right: 16px;
}

img {
  width: 100%;
}

.post_content-container img {
  border-radius: 16px;
}

.post_navigation a {
  color: $color-black;
}

.center {
  text-align: center;
}

blockquote {
  background: $color-grey-50;
  border-radius: 8px;
  padding: 20px 10px;
  margin: 12px 0px;

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 0 10px;
  }
}

.quote {
  font-size: 14px;
  font-style: italic;
  line-height: 1.5;
}

.testimonial {
  margin-bottom: 2em;
}

ul,
ol {
  line-height: 3;
}

ul .col {
  line-height: 1;
}

.img-left {
  float: left;
  width: 50%;
  margin-right: 1em;
}

.text-center {
  text-align: center;
}

#hero-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  // text-align: center;
}

#build-item {
  color: $color-grey;
}

#hero-image {
  width: 180px;
}

.secondary-button {
  display: inline-flex;
  gap: 4px;
  flex-direction: row;
  padding: 4px 12px;
  border: 1px solid transparent;
  border-radius: 8px;
  align-items: center;
  text-decoration: none;
  // margin-top: 16px;

  p {
    color: $color-grey;
    font-weight: 500;
  }

  &:hover {
    p {
      color: $color-black;
      text-decoration: none;
    }

    // border: 1px solid $color-black;
    // box-shadow: 0px 4px 4px 0px #00000025;
    transform: scale(1.01);
    background-color: $color-grey-25;
  }
}

.btn {
  border-radius: 7px;
  background: var(--button-fill-dark,
      linear-gradient(180deg, #3e3d3e 0%, #403e40 60%, #5e5d5e 100%));
  box-shadow: 0px -2px 2px 0.5px #000 inset,
    0px 0px 2px 1.5px rgba(255, 255, 255, 0.5) inset;

  display: inline-flex;
  gap: 4px;
  flex-direction: row;
  padding: 6px 16px;

  align-items: center;
  text-decoration: none;
  color: $color-white;
  cursor: pointer;

  p {
    color: $color-white;
    font-weight: 500;
    pointer-events: none;
    -webkit-user-select: none;
    /* Safari */
    user-select: none;
  }

  &:hover {
    background: var(--button-fill-dark,
        linear-gradient(180deg, #2c2b2c 0%, #2a282a 60%, #474647 100%));

    p {
      color: $color-white;
    }
  }

  &:active {
    // border-radius: 8px;
    background: linear-gradient(180deg,
        #383738 0%,
        #1a191b 79.91%,
        #181718 100%);
    box-shadow: 0px 0px 2px 1.5px rgba(255, 255, 255, 0.15) inset,
      0px 4px 2px 0.5px #000 inset;
    padding: 7px 16px 5px 16px;
  }
}

#availability {
  border-radius: 8px;
  background: var(--button-fill-dark,
      linear-gradient(180deg, #3e3d3e 0%, #403e40 79.91%, #5e5d5e 100%));
  box-shadow: 0px -2px 2px 0.5px #000 inset,
    0px 0px 2px 1.5px rgba(255, 255, 255, 0.5) inset;

  p {
    color: $color-white;
  }
}

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px #8c8c8c;
  }

  100% {
    box-shadow: 0 0 0 10px #00000000;
  }
}

#availability-dot {
  width: 8px;
  height: 8px;
  margin-right: 8px;
  background-color: $color-black;
  border-radius: 100%;

}

#availability-dot.yellow {
  background-color: $color-yellow;
}

.connect-list {
  display: flex;
  flex-direction: row;
  gap: 24px;
  flex-flow: wrap;
}

// TICKER
.marquee {
  overflow: hidden;
  white-space: nowrap;
  width: 300%;
  margin-left: -100%;
  position: relative;
}

.marquee-shade {
  top: 0;
  position: absolute;
  
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  background: linear-gradient(90deg, $color-white, transparent, $color-white);
  pointer-events: none;
}

.marquee-content {
  width: 300%;
  /* Since we have duplicated the content */
  display: flex;
  gap: 16px;
  // animation: marquee 20s linear infinite;
  overflow: hidden;
}

.marquee:hover .marquee-content {
  animation-play-state: paused;
}

.marquee-item {
  position: relative;
  overflow: hidden;
  border-radius: 16px;

  &:hover {
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }
}

.marquee-item img {
  // flex: 0 0 50%; /* Since we have two blocks of content */
  display: block;
  // max-height: 140px;
  // justify-content: space-around; /* Adjust as needed */
}

.workImage-item {
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  border: 1px solid #00000010;

  img {
    display: block;
  }

  &:hover {
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }
}

.workImage-item:hover .topRight-btn,
.workImage-item:hover .overlay {
  display: block;
}

.topRight-btn {
  display: none;
  position: absolute;
  top: 12px;
  font-size: small;
  padding: 12px 14px;
  border-radius: 24px;
  right: 12px;
  background-color: $color-white;
  color: $color-black !important;
  cursor: pointer;
  font-weight: bold;
  z-index: 9;

  /* Add your desired button styles here */
  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8;
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}

.center-btn {
  display: none;
  position: absolute;
  top: 50%;
  font-size: small;
  padding: 12px 14px;
  border-radius: 24px;
  left: 50%;
  background-color: $color-white;
  color: $color-black !important;
  cursor: pointer;
  font-weight: bold;
  z-index: 9;
  transform: translate(-50%, -50%);

  /* Add your desired button styles here */
  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8;
  }
}

.overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Adjust color and opacity as needed */
  pointer-events: none;
  // border-radius: 32px;
}

.marquee-item:hover .center-btn,
.marquee-item:hover .overlay {
  display: block;
}

.skill-list,
.stack-list,
.approach-list,
.workType-list {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  gap: 16px;
}

.sf-icon {
  width: 28px;
  height: 28px;
}

.stack-list,
.approach-list,
.workType-list {
  justify-content: space-between;
}

.workType-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0.25;
  transition: all 3s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    opacity: 1;
  }
}

.workType-item.active {
  opacity: 1;

  p {
    color: $color-black;
  }
}

.column {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}

.skill-item {
  padding: 4px 12px;
  border: 1px solid $color-grey-50;
  border-radius: 8px;
  white-space: nowrap;
}

.stack-image {
  max-width: 48px;
  filter: grayscale(1);
  display: block;
}

.tooltip-container {
  position: relative;
  display: inline-block;

  &:hover .stack-image {
    filter: unset;
  }
}

.team-list {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  gap: 8px;
}

.team-image {
  max-width: 24px;
  border-radius: 100%;
  display: block;
}

// .approach-list .tooltip{
//   min-width: 150px;
// }

.tooltip {
  visibility: hidden;
  background-color: black;
  color: #fff;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 24px);
  opacity: 0;
  transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1), opacity 0.3s;
  font-size: 12px;
  white-space: nowrap;

  p {
    margin: unset;
  }
}

.tooltip-title {
  color: #fff;
  line-height: 125%;
  margin: unset;
}

.tooltip-container:hover .tooltip {
  visibility: visible;
  transform: translate(-50%, -4px);
  opacity: 1;
}

.service-card {
  border: 1px solid $color-grey-50;
  border-radius: 16px;
  padding: 16px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  ul {
    line-height: 2;
  }
}

.service-workType-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 12px;
  border: 1px solid $color-grey-50;
  border-radius: 8px;
  white-space: nowrap;

}

.service-testimonial {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  border-radius: 16px;
  background-color: #80808010;
  padding: 12px;

}

.service-testimonial-top {
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
}

.service-testimonial-bottom {
  width: 100%;
}


.service-testimonial-img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  overflow: hidden;
}

.service-testimonial-content {
  flex: 1;
}


.newsletter-subs {
  display: flex;
}

.newsletter-pfp {
  border: 1px solid $color-black;
  border-radius: 16px;
  width: 24px;
  height: 24px;
  margin-right: -4px;
}

.leanUX-container {
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
}

.leanUX-icon {
  width: 24px;
  height: 24px;
}

.service-divider {
  border: 0px;
  border-bottom: 0.1px solid grey;
  width: 100%;
}

.service-cta-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    line-height: 16px;
  }
}

.service-buttons {
  display: flex;
  align-items: end;
  gap: 8px;
}

.service-btn {
  padding: 8px 12px;
  border-radius: 8px;
  background: rgba($color-black, 0.05);
}

// MEDIA QUERIES

@media (min-width: 920px) {
  .row {
    display: flex;
    flex-direction: row;
    gap: 24px;
    width: 100%;
  }
}

.col {
  margin-top: 20px;

  @media (min-width: 920px) {
    margin-top: 0px;
  }
}

@media (min-width: 920px) {
  .col--one-third {
    max-width: 33.33333%;
    // padding: 0 20px;
    -ms-flex-preferred-size: 33.33333%;
    flex-basis: 33.33333%;
  }

  .col--one-quarter {
    max-width: 25%;
    // padding: 0 20px;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
  }

  .col--one-half {
    max-width: 50%;
    // padding: 0 20px;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }

  .col--two-thirds {
    max-width: 66.66667%;
    // padding: 0 20px;
    -ms-flex-preferred-size: 66.66667%;
    flex-basis: 66.66667%;
  }

  .col--three-quarters {
    max-width: 75%;
    // padding: 0 20px;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
  }

  .col--full {
    max-width: 100%;
    // padding: 0 20px;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}

@media (max-width: 800px) {
  .main_nav.scrolled {
    width: 100%;
  }

  // .footer-button {
  // 	display: none;
  // }
}

@media (max-width: 600px) {
  body {
    display: unset;
    overflow: hidden;
  }

  .container {
    overflow-x: hidden;
  }

  .nav__social {
    display: none;
  }

  .nav-divider {
    display: none;
  }

  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  .blurred-backdrop {
    width: 80vw;
  }

  html,
  .main-container {
    padding: 8px;
  }

  .main_nav {
    top: 16px;
  }

  // .nav__home #home-image {
  // 	width: 24px;
  // 	height: 24px;
  // }

  #svg-design-icon,
  #svg-code-icon {
    zoom: 0.5;
  }

  .headline-icon {
    top: 2px;
  }

  .connect-list {
    flex-direction: column;
    gap: 8px;
  }
}

// DARK MODE

@mixin dark-mode {
  // background: $color-black !important;
  // color: $color-white;

  // background: linear-gradient(1deg, #1e1e1e 0%, #949494 72.92%, #7f9be1 100%);
  background-color: $color-black;

  // body {
  // 	background: conic-gradient(
  // 		from 90deg at 50% 50%,
  // 		rgba(49, 36, 53, 0.75) 37.56044000387192deg,
  // 		rgba(41, 37, 54, 0.75) 138.69070887565613deg,
  // 		rgba(36, 37, 53, 0.75) 215.78274965286255deg,
  // 		rgba(48, 38, 54, 0.75) 322.0032262802124deg
  // 	);
  // }

  #build-item[data-char] {
    color: transparent;
    position: relative;
    white-space: nowrap;
  }

  #build-item[data-char]::after {
    color: $color-white;
  }

  .footer-button {
    p {
      color: $color-grey;
    }

    &:hover {
      p {
        color: $color-white;
        text-decoration: none;
      }

      border: 1px solid $color-white;
      // box-shadow: 0px 0px 3px 0px $color-white;
      transform: scale(1.01);
      background-color: $color-black;
    }

    &:active {
      transform: scale(0.95);
    }
  }

  // .button::before{
  //   background: linear-gradient(180deg,#ffffff12 80%,#ffffff26);
  // }

  .btn {
    background: #303030;
  }

  .btn {
    background: linear-gradient(180deg, #f8f8f8 0%, #fefefe 100%);
    box-shadow: 0px 0px 2px 0px #fcfcfc inset, 0px -1px 1px 1px #b0b0b0 inset;

    p {
      color: $color-black;
      user-select: none;
    }

    &:hover {
      background: linear-gradient(180deg, #e8e7e7 0%, #edecec 100%);

      p {
        color: $color-black;
      }
    }

    &:active {
      background: linear-gradient(180deg,
          #e5e5e5 0%,
          #f3f3f3 9.38%,
          #f6f6f6 21.88%,
          #f4f4f4 60.94%,
          #fefefe 100%,
          #f6f6f5 100%);
      box-shadow: 0px 2px 1px 1px #b4b4b4 inset;
    }
  }

  #availability {
    border-radius: 8px;
    background: var(--button-fill-light,
        linear-gradient(180deg, #ececec 0%, #c2c2c2 82.29%, #ececec 100%));

    /* Button Effects/Light */
    box-shadow: 0px -2px 2px 0.5px rgba(0, 0, 0, 0.5) inset,
      0px 0px 2px 1.5px #fff inset;

    p {
      color: $color-black;
    }
  }

  #availability-dot {
    background-color: $color-white;
    border-radius: 100%;
  }

  #home-image:hover {
    outline: 1px solid rgb(255, 255, 255) !important;
  }

  .icon-container,
  .sf-icon {
    filter: invert(1);
  }

  .project-item {
    &:hover {
      background-color: #ffffff25 !important;

      .icon-container {
        filter: none;
      }
    }
  }

  .title {
    color: $color-white;
  }


.marquee-shade {
  background: linear-gradient(90deg, $color-black, transparent, $color-black);
}

  // @keyframes pulse-animation {
  // 	0% {
  // 		box-shadow: 0 0 0 0px #ffffff5d;
  // 	}
  // 	100% {
  // 		box-shadow: 0 0 0 20px #ffffff00;
  // 	}
  // }

  // #theme-toggle {
  // 	&:hover {
  // 		outline: 1px solid white !important;
  // 		color: $color-white;
  // 	}
  // }


  .leanUX-container img {

    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(216deg) brightness(111%) contrast(102%);

  }

  ;

  .section-title {
    color: $color-white;
    // font-weight: 500;
  }

  .home_link_li {
    &:hover {
      a {
        hr {
          border-bottom: 0.1px solid $color-white;
        }
      }
    }
  }

  a:hover {
    color: $color-white;

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $color-white;
    }
  }

  .main_nav {
    hr {
      background-color: $color-white;
    }
  }

  .nav__items .current {
    color: $color-white;
  }

  .nav__social img {
    filter: invert(1);
  }

  h1 {
    color: $color-white;
  }

  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  ul,
  li,
  div {
    color: $color-grey;
  }

  code {
    background: $color-charcoal;
    color: $color-orange;
  }

  .gradient-bg {

    h6,
    a {
      color: $color-black;
    }
  }

  .meta__primary {
    color: $color-white;
  }

  .meta__secondary {
    color: $color-white;
  }

  .tags__item {
    border-color: $color-grey;
    color: $color-white;
  }

  blockquote {
    background-color: $color-charcoal;
  }

  .blurred-backdrop {
    background-color: $color-black;
  }

  .workType-item.active p {
    color: $color-white;
  }
}

// FADE IN ANIMATIONS

@keyframes loadDown {
  from {
    transform: perspective(1200px) translateY(-150px) scale(1) rotate(0deg) translatez(0);
    opacity: 0;
  }

  to {
    transform: perspective(1200px);
    opacity: 1;
  }
}

.load-down {
  animation: loadDown 0.5s cubic-bezier(0.25, 0.34, 0.51, 1.07);
}

.fade-in {
  animation: fadeIn 0.75s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

// BACKGROUND GRADIENT STYLES FOR BEAUZAR

.gradient-bg {
  background: linear-gradient(270deg,
      #f5eae3,
      #f5f1e2,
      #f5e1f8,
      #f8f4e4,
      #e4f5f7,
      white,
      white);
  background-size: 1000% 1000%;

  -webkit-animation: AnimationName 30s ease infinite;
  -moz-animation: AnimationName 30s ease infinite;
  animation: AnimationName 30s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

* {
  transition: background-color 0.5s ease, color 0.5s ease;
}

.dark-theme {
  @include dark-mode;

  @media (prefers-color-scheme: dark) {
    html {
      @include dark-mode;
    }
  }
}